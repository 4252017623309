<template>  
  <div slot="left">
    <article>
      <p>本网站提供数据api接口，接口地址：<a :href="apiUrl">{{ apiUrl }}</a><br /></p>
      <p>
        返回最近10期的开奖号码<br />
        请求方式：get<br />
        返回类型：json<br />
        返回结果说明：<br />
      </p>
      <div><pre v-highlightjs><code class="json">{{ apiResultSample }}</code></pre></div>
      <p>
        说明:<br />
        1. IssueNo: 期号<br />
        2. IssueTime: 开奖时间<br />
        3. LottorNums: 开奖结果<br />
      </p>
    </article>
  </div>
</template>

<script>
export default {  
  inject: {    
    constant: "constant"
  },
  data() {
    return {
      apiUrl: "",       
      apiResultSample: ""
    };
  },
  beforeMount(){    
    let tmpSample = this.constant.TypeDict[this.$route.params.lotto];
    if (tmpSample) {
      this.apiResultSample = JSON.stringify(tmpSample.content, null, 4);      
    } 
  },
  mounted(){
    this.apiUrl = window.location.protocol + "//" + window.location.hostname + "/api/" + this.$route.params.lotto + "/GetDataByNow";;
  }
};
</script>