<template>
    <div slot="left">
      <article>
        <template v-if="this.$route.params.lotto === enums.LOTTO_TYPE.HSPK">
          <h6>投注</h6>
          <p>每期{{ $productName }} PK拾由10辆编号为1-10的赛车进行比赛, 依据比赛结果，我们提供数十种有趣的玩法供您选择体验。</p>
          <h6>什么时候玩</h6>
          <p>我们每3分钟开一次奖, 一天共480次, 全年无休。</p>
          <h6>开奖结果</h6>
          <p>每期由1-10总共10辆赛车进行比赛，抵达目的顺序为比赛名次。第一个抵达的车号为冠军，第二个抵达的车号为亚军，第三个抵达的车号为季军以此类推。我们将每3分钟发布一次比赛结果，获奖号码将出现在我们的官方网站上。</p>
        </template>
        <template v-if="this.$route.params.lotto === enums.LOTTO_TYPE.HSSSC">
          <h6>投注</h6>
          <p>每期{{ $productName }}时时彩由0-9共10個號碼开出一个5位数作为中奖号码，根据选中位置的不同，我们提供数十种有趣的玩法供您选择体验。</p>
          <h6>什么时候玩</h6>
          <p>我们每60秒开一次奖，一天共1440次，全年无休。</p>
          <h6>开奖结果</h6>
          <p>每期开出一个5位数作为中奖号码，分别为万位、千位、百位、十位和个位，每位号码为0-9。我们将每60秒发布一次中奖号码，获奖号码将出现在我们的官方网站上。</p>
        </template>
        <template v-if="this.$route.params.lotto === enums.LOTTO_TYPE.HSSFC">
          <h6>投注</h6>
          <p>每期{{ $productName }} 3分彩由0-9共10個號碼开出一个5位数作为中奖号码，根据选中位置的不同，我们提供数十种有趣的玩法供您选择体验。</p>
          <h6>什么时候玩</h6>
          <p>我们每3分钟开一次奖，一天共480次，全年无休。</p>
          <h6>开奖结果</h6>
          <p>每期开出一个5位数作为中奖号码，分别为万位、千位、百位、十位和个位，每位号码为0-9。我们将每3分钟发布一次中奖号码，获奖号码将出现在我们的官方网站上。</p>
        </template>
        <template v-if="this.$route.params.lotto === enums.LOTTO_TYPE.HS115">
          <h6>投注</h6>
          <p>每期从01-11共11个号码中任选01-11个号码投注，每期开出5个号码作为中奖号码，根据选中位置的不同，我们提供数十种有趣的玩法供您选择体验。</p>
          <h6>什么时候玩</h6>
          <p>我们每3分钟开一次奖，一天共480次，全年无休。</p>
          <h6>开奖结果</h6>
          <p>每期开出一个5位数作为中奖号码，分别为万位、千位、百位、十位和个位，每位号码互不相同。我们将每3分钟发布一次中奖号码，获奖号码将出现在我们的官方网站上。</p>
        </template>
      </article>
    </div>
</template>

<script>
export default {
  inject: {   
    enums: "enums"
  }
};
</script>