export default {
    statistics: (datas, middle) =>{
        const numbers = datas.map(x => {
            const item = {...x};
            item.winSum = item.numbers[0] + item.numbers[1];
            item.typeText = [
                item.numbers[0] < item.numbers[9] ? "虎" : "龙",
                item.numbers[1] < item.numbers[8] ? "虎" : "龙",
                item.numbers[2] < item.numbers[7] ? "虎" : "龙",
                item.numbers[3] < item.numbers[6] ? "虎" : "龙",
                item.numbers[4] < item.numbers[5] ? "虎" : "龙"
            ];
            item.typeColor = [
                item.numbers[0] < item.numbers[9] ? "green" : "orange",
                item.numbers[1] < item.numbers[8] ? "green" : "orange",
                item.numbers[2] < item.numbers[7] ? "green" : "orange",
                item.numbers[3] < item.numbers[6] ? "green" : "orange",
                item.numbers[4] < item.numbers[5] ? "green" : "orange"
            ];
            return item;
        });
        numbers.maxCount = [];
        for(let i=0; i < 10; i++){
            const arr = numbers.map(x => x.numbers[i]);
            const count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            arr.forEach(current => {
                const index = current-1;
                count[index] = count[index] ? count[index] + 1: 1;
            });
            let max = [0, 0];
            count.forEach((current, index) => {
                if(current > max[1]){
                    max = [index+1, current];
                }
            });
            numbers.maxCount[i] = max;
        }
        numbers.dragonCount = [];
        numbers.tigerCount = [];
        numbers.tigerDragonMax = [];
        for(let i=0; i < 5; i++){
            numbers.dragonCount[i] = 0;
            numbers.forEach(current => {
                if(current.typeColor[i] == "orange"){
                    numbers.dragonCount[i]++;
                }
            })
            numbers.tigerCount[i] = 0;
            numbers.forEach(current => {
                if(current.typeColor[i] == "green"){
                    numbers.tigerCount[i]++;
                }
            })
            numbers.tigerDragonMax[i] = 0;
            numbers.tigerDragonMax[i] = Math.max(numbers.dragonCount[i], numbers.tigerCount[i]);
        };
        return numbers;
    }
};