export const commonSort = {
    data() {
        return {
            sortType: 'sort_down'
        };
    },
    methods: {
        onChangeSort() {
            if (this.sortType === "sort_down") {
                this.sortType = "sort_up";
            } else {
                this.sortType = "sort_down";
            }
            
            this.queryData.reverse();
        }
    }
};