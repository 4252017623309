<template>
  <div>
    <banner-image :theme="productTheme" />
    <banner :theme="productTheme" />
    <container />
  </div>
</template>

<script>
import bannerImage from "../components/BannerImage";
import banner from "../components/Banner";
import container from "../components/Container";

export default {
  inject: {
    service: 'service',
    constant: "constant",
    enums: "enums"
  },
  components: {
    bannerImage,
    banner,
    container
  },
  data() {
    return {
      productTheme: "pk10",
    };
  },
  mounted() {
    this.changeTheme();
  },
  methods: {
    changeTheme() {      
      switch (this.$route.params.lotto) {
        case this.enums.LOTTO_TYPE.HSPK:
          this.productTheme = "pk10";
          break;
        case this.enums.LOTTO_TYPE.HSSSC:
          this.productTheme = "lotto_hour";
          break;
        case this.enums.LOTTO_TYPE.HSSFC:
          this.productTheme = "lotto_3min";
          break;
        case this.enums.LOTTO_TYPE.HS115:
          this.productTheme = "lotto_11pick5";
          break;
      }
      this.getDataAsync(this.$route.params.lotto);      
    },
    async getDataAsync(lotto) {        
        let end = this.$momentTz();
        let begin = this.$momentTz();

        if(this.$store.state.historyQueryIdx === 0){
            begin = begin.milliseconds(0).seconds(0).minutes(0).hours(0);
        } else {
          if (this.constant.QueryByAllDate.indexOf(this.$store.state.historyQueryIdx) >= 0){
            end = end.milliseconds(0).seconds(0);
            begin = begin.milliseconds(0).seconds(0);
          }
          begin = begin.subtract(this.constant.TypeDict[lotto].dateFilter[this.$store.state.historyQueryIdx], 'minute');
        }

        var response = await this.service.getDataAsync(lotto, 
          begin.format("YYYY-MM-DD HH:mm:ss"), 
          end.format("YYYY-MM-DD HH:mm:ss")); 
        
        if (response && response.lotteryInfoList?.length > 0) {    
          response.lotto = lotto;
          this.$store.commit('updateIssueNewest', response);                             
        }
    }
  },
  watch: {
    "$route.params.lotto": function (newVal) {      
      this.changeTheme();
      this.$store.commit('updateIssueNewest', null);
    },
    "$store.state.historyQueryIdx": function (newVal) {
      this.getDataAsync(this.$route.params.lotto);      
    },
    "$store.state.currentPageNeedRefresh": function (newVal) {
      this.getDataAsync(this.$route.params.lotto);      
    }
  }
};
</script>