<template>
  <div>
    <div class="count_win container">
      <div class="count">
        <div class="girls">
          <img :src="`../images/girl_${ theme }.png`" alt="" />
        </div>
        <h1>开奖倒数</h1>
        <p>
          <img src="/images/ic_clock.svg" alt="" />每{{ interval }}分钟开奖
        </p>
        <div class="count_box">
          <p>
            距 <span v-if="Number(currentIssue.issueNo) > 0">{{ Number(currentIssue.issueNo) + 1 }}</span> 期开奖仅有
          </p>
          <div class="count_num">
            <li>{{ minutes }}</li>
            <li>{{ seconds }}</li>
          </div>
        </div>
      </div>
      <div :class="'newest_win ' + theme">
        <h1>
          {{ $productName }}<span>{{ typeDescTxt }}</span>
        </h1>
        <p>第 {{ (Number(currentIssue.issueNo) > 0) ? currentIssue.issueNo : ''}} 期 开奖号码</p>
        <ul :class="$route.params.lotto === enums.LOTTO_TYPE.HSPK ? 'pk10' : 'lotto'">
          <template v-if="$route.params.lotto === enums.LOTTO_TYPE.HSPK">
            <li v-for="(ball, index) in currentIssue.numbers" :key="index" :class="'num_' + ('0' + ball).slice(-2)">
              {{ ("0" + ball).slice(-2) }}
            </li>
          </template>
          <template v-else>
            <li v-for="(ball, index) in currentIssue.numbers" :key="index" :class="index === 0 || index === 4 ? 'ball_pic_orange' : 'ball_pic_cyan'">
              {{ ball }}
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const MaxReloadCount = 5;
const StartGetSec = 2;
const SegmentSec = 2;

export default {
  inject: {
    constant: "constant",
    enums: "enums"
  },
  props: ["theme"],
  data() {
    return {
      queryData: null,
      currentIssue: null,
      minutes: "--",
      seconds: "--",
      sysSecond: 0,
      interval: 0,
      reloadCount: 0,
      prevRoute: null
    };
  }, 
  computed: {
    typeDescTxt: function () {      
      if (this.prevRoute !== this.$route.params.lotto) { 
        this.setPrevRoute();   
        this.initDataChange();
      }
      switch (this.$route.params.lotto) {
        case this.enums.LOTTO_TYPE.HSPK:
          return " PK拾";
        case this.enums.LOTTO_TYPE.HSSSC:
          return "时时彩";
        case this.enums.LOTTO_TYPE.HSSFC:
          return " 3分彩";
        case this.enums.LOTTO_TYPE.HS115:
          return " 11选5";
      }
    }
  },
  beforeMount(){    
    if (!this.prevRoute){
      this.prevRoute = this.$route.params.lotto;
    }
    this.initDataChange();
  },
  mounted(){    
    setInterval(() => {
      this.cd();
    }, (1000));    
  },
  methods: {
    showView() {
      this.currentIssue = this.queryData?.lotteryInfoList[0];
      this.interval = this.constant.TypeDict[this.$route.params.lotto].interval;

      let end_time = this.$momentTz(this.currentIssue.issueTime);
      let now = this.$momentTz(this.queryData.currentDateTime);
      this.sysSecond = end_time.diff(now, "seconds") + this.interval * 60; 
      this.reloadCount = 0;

      this.refreshCountdownText();
    },
    cd() {
      this.sysSecond--;
      if (this.sysSecond < 0){      
          // 2秒之後才開始抓每次再間隔二秒再抓
          if (this.reloadCount <= MaxReloadCount && 
            this.sysSecond < -StartGetSec && 
            Math.abs(this.sysSecond) % 2 === 0) 
          {            
            this.reloadCount++;            
            this.$store.commit('callCurrentPageNeedRefresh');
          }                    
          return;
      }
      this.refreshCountdownText();
    },
    refreshCountdownText() {
      this.minutes = Math.floor((this.sysSecond / 60) % 60);
      this.seconds = Math.floor(this.sysSecond % 60);

      if (this.minutes < 0) this.minutes = 0;
      if (this.seconds < 0) this.seconds = 0;
    },
    setPrevRoute() {
      this.prevRoute = this.$route.params.lotto;
    },
    initDataChange(val){
      let te = (val) ? val.lotto : null;

      if (te !== null && this.$route.params.lotto === te) {        
        this.queryData = val;      
        this.showView();      
      } else {
        this.interval = 0;
        this.minutes = "--";
        this.seconds = "--";
        this.sysSecond = 0;
        this.reloadCount = 0;
        this.currentIssue = {
            issueNo: 0,
            numbers: []
        }
      }
    }
  },
  watch: {
    "$store.state.issueNewest": function (newVal) {
      this.initDataChange(newVal);      
    }
  }
};
</script>