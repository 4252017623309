<template>
  <div slot="left">      
    <lottoTrendFilter />   
    <lottoTrendFilterMobile />   
    <trendPK10 v-if="isHSPK"/>
    <trendMix v-if="!isHSPK && isMixType"/>
    <trendStart v-if="!isHSPK && !isMixType"/>
  </div>
</template>

<script>
import lottoTrendFilter from "../components/LottoTrendFilter";
import trendPK10 from "../components/LottoTrendPK10";
import trendMix from "../components/LottoTrendMix";
import trendStart from "../components/LottoTrendStar";
import lottoTrendFilterMobile from "../components/LottoTrendFilterByMobile";
import { common } from '../common.js'

export default {
  mixins: [common],
  components: { 
    lottoTrendFilter,   
    lottoTrendFilterMobile,      
    trendPK10,
    trendMix,    
    trendStart
  }
};
</script>