import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router';
import service from './service';
import i18n from './i18n';
import enums from './enums';
import constant from './constant';
import VueHighlightJS from 'vue-highlightjs';

//平台名稱
Vue.prototype.$productName = "AMD";
Vue.use(i18n);
Vue.use(Vuex);
Vue.use(VueHighlightJS);

const store = new Vuex.Store({
  state: {
    issueNewest: null,
    historyQueryIdx: 0,
    trendTypeIdx: 0,
    currentPageNeedRefresh: 0
  },
  mutations: {
    updateIssueNewest(state, value) {
      if (!value)
        return;

      state.issueNewest = value;
    },
    updateHistoryQueryIdx(state, value) {
      state.historyQueryIdx = value;
    },
    updateTrendTypeIdx(state, value) {
      state.trendTypeIdx = value;
    },
    callCurrentPageNeedRefresh(state){      
      state.currentPageNeedRefresh++;
    }
  }
})

new Vue({
  provide() {
    return { service, enums, constant };
  },
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')

