const LOTTO_TYPE = {
    HSPK: 'HSPK',
    HSSSC: 'HSSSC',
    HSSFC: 'HSSFC',
    HS115: 'HS115'
  };

const LOTTO_ACTION_TYPE = {
    History: 'History',
    Trend: 'Trend',
    Rule: 'Rule',
    Api: 'Api'
  };

const LINKER_TYPE = {
    Privacy: 'Privacy',
    Rule: 'Rule',
    Problem: 'Problem',
    Rational: 'Rational'
  };

export default {
  LOTTO_TYPE,
  LOTTO_ACTION_TYPE,
  LINKER_TYPE
};
