<template>
  <div class="charts_detail">
    <table class="Dtable Old">
      <thead>
        <tr>
          <th width="120" rowspan="2" class="sort" style="cursor: pointer" @click="onChangeSort()">
            期号<i :class="`icon ${sortType}`"></i>
          </th>
          <th width="154" rowspan="2">开奖时间</th>
          <th width="276" rowspan="2">开奖号码</th>
          <th width="400" colspan="10">前五名龙虎</th>
          <th width="70" rowspan="2">冠亚和值</th>
        </tr>
        <tr class="NBold">
          <th colspan="2" width="80">冠军</th>
          <th colspan="2" width="80">亚军</th>
          <th colspan="2" width="80">第三名</th>
          <th colspan="2" width="80">第四名</th>
          <th colspan="2" width="80">第五名</th>
        </tr>
      </thead>
      <tbody id="history_item_box">
        <tr v-for="(item, index) in queryData" :key="index">
          <td>{{ item.issueNo }}</td>
          <td>{{ item.issueTime }}</td>
          <td>
            <em v-for="(ball, index2) in item.numbers" :key="index2" :class="`signicon emicon e0${ ball }`">{{ ball }}</em>
          </td>
          <td v-for="(lfText, index3) in item.typeText" :key="index3" colspan="2">
            <span :class="item.typeColor[index3]">{{ lfText }}</span>
          </td>
          <td colspan="2">{{ item.winSum }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">本页合计：<span class="green" id="totalIssue">{{queryData.length}}</span>
          </td>
          <td id="maxCalculorBox">
            <p class="charts_number">              
              <span v-for="(item, index) in queryData.maxCount" :key="index">{{item[1]}}</span>              
            </p>
            <p class="charts_icon">              
              <em v-for="(item, index) in queryData.maxCount" :key="index" :class="`signicon emicon e0${ item[0] }`">{{item[0]}}</em> 
            </p>
            <p><span class="orange">*</span> 该位置出现最多的车次</p>
          </td>               
          <td class="borderwhite" v-for="(calCount, index) in queryData.dragonCount" :key="'td1' + index">
            <span class="times redcolor pk10">
              <em :style="`height:${calCount * 100.0 / queryData.tigerDragonMax[index]}px`">
                <b><i>龙:{{ calCount }}</i></b>
              </em>
            </span>
          </td>
          <td class="sumCalculor" v-for="(calCount, index) in queryData.dragonCount" :key="'td2' + index">
            <span class="times combatcolor pk10">
              <em :style="`height:${ queryData.tigerCount[index] * 100.0 / queryData.tigerDragonMax[index] }px`">
                <b><i>虎:{{ queryData.tigerCount[index] }}</i></b>
              </em>
            </span>
          </td>  
          <td>&nbsp;</td>
        </tr>
      </tfoot>
    </table>
    <div class="charts_exper">
      <h6>参数说明</h6>
      <ul>
        <li>
          <span class="green">[号码分布]</span> 本次开奖所有车次的排名情况。
        </li>
        <li><span class="green">[冠军]</span> 第一名的车次号码。</li>
        <li><span class="green">[亚军]</span> 第二名的车次号码。</li>
        <li><span class="green">[季位]</span> 第三名的车次号码。</li>
        <li><span class="green">[第四名]</span> 第四名的车次号码。</li>
        <li><span class="green">[第五名]</span> 第五名的车次号码。</li>
      </ul>
    </div>
  </div>
</template>

<script>
import PK10Handle from "../pk10";
import { commonSort } from '../commonSort.js'

export default {
  mixins: [commonSort],
  data() {
    return {
      queryData: []
    };
  },
  mounted(){
    this.processData();    
  },
  methods:{
    processData() {      
      this.queryData = PK10Handle.statistics([]);
      let trendData = this.$store.state.issueNewest;      
      if (trendData && this.$route.params.lotto === trendData.lotto) {
        this.queryData = PK10Handle.statistics(trendData.lotteryInfoList);
      }
    }    
  },
  watch: {
    "$store.state.issueNewest": function (newVal) {      
      if (newVal){
        this.processData();     
        this.sortType = "sort_down";
      }
    }
  }
};
</script>