
//             0      1     2     3     4     5      6      7     8      9      10    11    12     13     14    15     16     17    18     19    20
const prime=[false, true, true, true, false, true, false, true, false, false, false, true, false, true, false, false, false, true, false, true, false];
// 時時彩(0-9)，3分彩(0-9)，11選五(1-11)
export default {
    statistics: (datas, middle) =>{
        const dist = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const diffRadio = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const bigRadio = [0, 0, 0, 0, 0, 0];
        const evenRadio = [0, 0, 0, 0, 0, 0];
        const primeRadio = [0, 0, 0, 0, 0, 0];
        const statisticRadio = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ];
        const numbers = datas.map((x, index) => {
            const item = {...x};
            item.total = 0;
            item.dist = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            item.diff = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            item.statistic = [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            ];
            item.bigCount = 0;
            item.oddCount = 0;
            item.primeCount = 0;
            let max = 0;
            let min = 11;
            item.numbers.forEach((current, numberIndex) => {
                // 計算總和
                item.total = item.total + parseInt(current);

                // 計算各個號碼出現次數
                item.dist[current]++;
                dist[current]++;

                // 計算奇偶數總和
                if((current % 2) != 0){
                    item.oddCount++;
                }

                // 計算最大最小值
                if (current > max) {
                    max = current;
                }

                if (current < min) {
                    min = current;
                }

                if (current >= middle ){
                    item.bigCount++;
                }

                // 計算質合比
                if(prime[current]){
                    item.primeCount++;
                }
                item.statistic[numberIndex][current]++;
                statisticRadio[numberIndex][current]++;
            });
            item.diff[max - min]++;
            diffRadio[max - min]++;
            bigRadio[bigRadio.length - 1 - item.bigCount]++;
            primeRadio[primeRadio.length - 1 - item.primeCount]++;
            evenRadio[evenRadio.length - 1 - item.oddCount]++;
            return item;
        });
        numbers.dist = dist;
        numbers.distMax = Math.max(...dist);
        numbers.diffRadio = diffRadio;
        numbers.diffRadioMax = Math.max(...diffRadio);
        numbers.bigRadio = bigRadio;
        numbers.bigRadioMax = Math.max(...bigRadio);
        numbers.primeRadio = primeRadio;
        numbers.primeRadioMax = Math.max(...primeRadio);
        numbers.evenRadio = evenRadio;
        numbers.evenRadioMax = Math.max(...evenRadio);
        numbers.statisticRadio = statisticRadio;
        numbers.statisticRadioMax = [];
        numbers.statisticRadio.forEach((item, i) => {
            numbers.statisticRadioMax[i] = Math.max(...item);
        });
        return numbers;
    }
};