<template> 
  <div class="m_filter">
    <div class="m_filter_item">
      <select ref="trendTypeOpts" @change="setTrendType($betterParseInt($event.target.value))" v-if="!isHSPK">
        <option value="0">五星综合</option>
        <option value="1">五星走势</option>
      </select>
      <select @change="setQueryRange($event.target.value)">
        <option v-for="(item, index) in constant.HistoryQueryItem" :key="index" :value="index">{{ item }}</option>
      </select>
    </div>
  </div>    
</template>

<script>
import { common } from '../common.js'

export default {
  mixins: [common],
  inject: {
    constant: "constant"
  },
  mounted() {
    if (this.$refs.trendTypeOpts) {
      this.$refs.trendTypeOpts.selectedIndex = this.$store.state.trendTypeIdx;
    }
  }
};
</script>