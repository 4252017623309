<template>
  <div>
    <div class="container tabs">
      <ul>
        <li>
          <router-link :to="enums.LOTTO_ACTION_TYPE.History" :class="isHistory ? 'select':''">
            <img src="/images/ic_tab_history.svg" alt="" />历史开奖
          </router-link>
        </li>
        <li>
          <router-link :to="enums.LOTTO_ACTION_TYPE.Trend" :class="isTrend ? 'select':''">
            <img src="/images/ic_tab_trend.svg" alt="" />走势图
          </router-link>
        </li>
        <li>
          <router-link :to="enums.LOTTO_ACTION_TYPE.Rule" :class="isRule ? 'select':''">
            <img src="/images/ic_tab_rule.svg" alt="" />玩法规则
          </router-link>
        </li>
        <li>
          <router-link :to="enums.LOTTO_ACTION_TYPE.Api" :class="isApi ? 'select':''">
            <img src="/images/ic_tab_api.svg" alt="" />接口调用
          </router-link>
        </li>
      </ul>
      <img src="/images/fly_chips.png" class="bg_chips" alt="" />
    </div>
    <div class="container tab_content_go_cover">
      <div class="tab_content">
        <div class="title" :class="themeTitleStyle">
          <img src="/images/title_pattern.svg" alt="" />
          <span>{{ themeTitle }}</span>
          <img src="/images/title_pattern.svg" class="flip" alt="" />
        </div>
        <div class="main_content">
          <div class="left_content" :class="themeTitleStyle">
            <img :src="`/images/m_${ themeRight }.png`" :class="`m_${ themeRight }`" alt="" />
            <img :src="`/images/m_${ themeLeft }.png`" :class="`m_${ themeLeft }`" alt="" />
            <lotto-history v-if="isHistory" />
            <lotto-trend v-if="isTrend" />
            <lotto-rule v-if="isRule" />
            <lotto-api v-if="isApi" />
          </div>
          <lotto-right-image v-if="showRightImage"/>
          <lotto-right-Query-Options v-if="isHistory"/>
        </div>
      </div>
      <img :src="`/images/pattern_${ typeName }.png`" class="tab_pattern" alt="" />
      <img :src="`/images/tab_bg_${ typeName }.jpg`" class="tab_bg" alt="" />
    </div>
    
  </div>
</template>

<script>
import lottoHistory from "../components/LottoHistory";
import lottoTrend from "../components/LottoTrend";
import lottoRule from "../components/LottoRule";
import lottoApi from "../components/LottoApi";
import lottoRightImage from "../components/LottoRightImage";
import LottoRightQueryOptions from "../components/LottoRightQueryOptions";
import { common } from '../common.js'

export default {
  mixins: [common],
  inject: {   
    enums: "enums"
  },
  components: {
    lottoHistory,
    lottoTrend,
    lottoRule,
    lottoApi,
    lottoRightImage,
    LottoRightQueryOptions
  },   
  data() {
    return {
      productTheme: "pk10",
      productRouter: "",
      themeTitle: "",
      themeTitleStyle: "",
      themeRight: "",
      themeLeft: "",
      typeName: ""
    };
  },
  computed: {
    showRightImage: function () {      
      return this.$route.params.item === this.enums.LOTTO_ACTION_TYPE.Rule || 
        this.$route.params.item === this.enums.LOTTO_ACTION_TYPE.Api;
    }
  },
  mounted(){
    this.productRouter = this.$route.params.lotto;
    this.changeTheme();
    this.changeLottoItem();
  },
  methods:{
    changeTheme() {      
      this.themeRight = "ball_9";
      this.themeLeft = "ball_1";
      switch (this.$route.params.lotto) {
        case this.enums.LOTTO_TYPE.HSPK:
          this.typeName = "pk10";
          this.themeRight = "coin_right";
          this.themeLeft = "coin_left";
          break;
        case this.enums.LOTTO_TYPE.HSSSC:
          this.typeName = "lotto_hour";
          break;
        case this.enums.LOTTO_TYPE.HSSFC:
          this.typeName = "lotto_3min";
          break;
        case this.enums.LOTTO_TYPE.HS115:
          this.typeName = "lotto_11pick5";
          break;
      }
    },
    changeLottoItem() {      
      this.themeTitleStyle = '';
      switch(this.$route.params.item){
        case this.enums.LOTTO_ACTION_TYPE.History:
          this.themeTitle = '历史开奖';          
          break;
        case this.enums.LOTTO_ACTION_TYPE.Trend:
          this.themeTitle = '走势图';
          this.themeTitleStyle = 'trend';
          break;
        case this.enums.LOTTO_ACTION_TYPE.Rule:
          this.themeTitle = '玩法规则';          
          break;
        case this.enums.LOTTO_ACTION_TYPE.Api:
          this.themeTitle = '接口调用';          
          break;
      }
    }
  },
  watch: {
    "$route.params.lotto": function (newVal) {      
      this.changeTheme();
    },
    "$route.params.item": function (newVal) {      
      this.changeLottoItem();
    }
  }
};
</script>