<template>    
  <div slot="right">
    <div class="right_content">
      <img :src="`/images/pattern_${ rightContent }_sub_tab.png`" :class="`pattern_${ rightContent }_sub_tab`" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  inject: {   
    enums: "enums"
  },
  data() {
    return {
      rightContent: ""
    };
  }, 
  mounted(){
    this.rightContent = "ball";
    if (this.$route.params.lotto === this.enums.LOTTO_TYPE.HSPK) {
      this.rightContent = "pk10";
    }    
  }
};
</script>