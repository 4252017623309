<template>
  <!--五星综合-->
  <div class="charts_detail">
    <table class="Dtable Old">
      <thead>
        <tr>
          <th width="64" rowspan="2" class="sort" style="cursor: pointer" @click="onChangeSort()">
            期号<i :class="`icon ${sortType}`"></i><!--sort_up改sort_down可以替換排序上下箭頭-->
          </th>
          <th width="96" rowspan="2">开奖号码</th>
          <th :colspan="columnSize">号码分布</th>
          <th :colspan="columnSize">号码跨度</th>
          <th colspan="6">大小比</th>
          <th colspan="6">奇偶比</th>
          <th colspan="6">质合比</th>
          <th width="40" rowspan="2">和值</th>
        </tr>
        <tr class="NBold">
          <th v-for="(n, index) in columnSize" :key="'h1' + index">{{ isHS115 ? n : index }}</th>
          <th v-for="(n, index) in columnSize" :key="'h2' + index">{{ index }}</th>
          <th v-for="(n, index) in 6" :key="'h3' + index">{{ 6 - n }}:{{ n - 1 }}</th>
          <th v-for="(n, index) in 6" :key="'h4' + index">{{ 6 - n }}:{{ n - 1 }}</th>
          <th v-for="(n, index) in 6" :key="'h5' + index">{{ 6 - n }}:{{ n - 1 }}</th>
        </tr>
      </thead>
      <tbody id="history_item_box">
        <tr v-for="(item, index) in queryData" :key="index">
          <td>{{ item.issueNo }}</td>
          <td><b>{{ isHS115 ? item.numbers.map(x => {return ("0" + x).slice(-2)}).join(",") : item.numbers.join(",") }}</b></td>
          <!--号码分布-->          
          <td v-for="(current, index) in item.dist" :key="'b1' + index" class="lightblue">
            <em :class="current > 0 ? (isHS115 ? 'signicon b_1' : (current > 1 ? 'signicon b_5': 'signicon b_1')) : ''">
              {{ current > 0 ? (isHS115 ? ('0' + (index + 1)).slice(-2) : index) : "" }}
            </em>
          </td>
          <!--号码跨度-->
          <td v-for="(current, index) in item.diff" :key="'b2' + index" class="lightred">
            <em :class="current > 0 ? 'signicon b_2' : ''">
              {{ current > 0 ? (isHS115 ?  ('0' + index).slice(-2) : index) : "" }}
            </em>
          </td>
          <!--大小比-->
          <td v-for="(current, index) in [5, 4, 3, 2, 1, 0]" :key="'b3' + index" class="ttblue">
            <em :class="current === item.bigCount ? 'charts_dot b_3' : ''">
              {{ current === item.bigCount ? item.bigCount + ':' + (selectCount - item.bigCount) : ''}}
            </em>
          </td>
          <!--奇偶比-->
          <td v-for="(current, index) in [5, 4, 3, 2, 1, 0]" :key="'b4' + index" class="lightgreen">
            <em :class="current === item.oddCount ? 'charts_dot b_4' : ''">
              {{ current === item.oddCount ? item.oddCount + ':' + (selectCount - item.oddCount) : '' }}
            </em>
          </td>
          <!--质合比-->
          <td v-for="(current, index) in [5, 4, 3, 2, 1, 0]" :key="'b5' + index" class="ttblue">
            <em :class="current === item.primeCount ? 'charts_dot b_3' : ''">
              {{ current === item.primeCount ? item.primeCount + ':' + (selectCount - item.primeCount) : '' }}
            </em>
          </td>
          <!--和值-->
          <td>{{ item.total }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">
            当前出现次数<br />（总数:<span class="green" id="totalIssue">{{ queryData.length }}</span >）
          </td>
          <!--号码分布-->
          <td v-for="(current, index) in queryData.dist" :key="'ff1' + index" class="borderwhite">
            <span :class="`times disps ${index % 2 != 0 ? 'darkBlueColor' : 'bluecolor'}`">
              <em :style="`height: ${ (100.0 * current) / queryData.distMax }px;`">
                <b><i>{{ current }}</i></b>
              </em>
            </span>
          </td>
          <!--号码跨度-->
          <td v-for="(current, index) in queryData.diffRadio" :key="'ff2' + index" class="borderwhite">
            <span :class="`times spans ${index % 2 != 0 ? 'pinkcolor' : 'redcolor'}`">
              <em :style="`height: ${ (100.0 * current) / queryData.diffRadioMax }px;`">
                <b><i>{{ current }}</i></b>
              </em></span>
          </td>
          <!--大小比-->
          <td v-for="(current, index) in queryData.bigRadio" :key="'ff3' + index" class="borderwhite">
            <span :class="`times sizeRatios ${index % 2 == 0 ? 'greencolor' : 'aquacolor'}`">
              <em :style="`height: ${ (100.0 * current) / queryData.bigRadioMax }px;`">
                <b><i>{{ current }}</i></b>
              </em></span>
          </td>
          <!--奇偶比-->
          <td v-for="(current, index) in queryData.evenRadio" :key="'ff4' + index" class="borderwhite">
            <span :class="`times parityRatio ${index % 2 == 0 ? 'verdancycolor' : 'combatcolor'}`">
              <em :style="`height: ${ (100.0 * current) / queryData.evenRadioMax }px;`">
                <b><i>{{ current }}</i></b>
              </em></span>
          </td>
          <!--质合比-->
          <td v-for="(current, index) in queryData.primeRadio" :key="'ff5' + index" class="borderwhite">
            <span :class="`times primeCompositeRatio ${index % 2 == 0 ? 'greencolor' : 'aquacolor'}`">
              <em :style="`height: ${ (100.0 * current) / queryData.primeRadioMax }px;`">
                <b><i>{{ current }}</i></b>
              </em></span>
          </td>
          <td rowspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td rowspan="2" colspan="2">&nbsp;</td>
          <td v-for="(n, index) in columnSize" :key="'f1' + index">{{ isHS115 ? n : index }}</td>
          <td v-for="(n, index) in columnSize" :key="'f2' + index">{{ index }}</td>
          <td v-for="(n, index) in fixedColumnSize" :key="'f3' + index">{{ fixedColumnSize - n }}:{{ n - 1 }}</td>
          <td v-for="(n, index) in fixedColumnSize" :key="'f4' + index">{{ fixedColumnSize - n }}:{{ n - 1 }}</td>
          <td v-for="(n, index) in fixedColumnSize" :key="'f5' + index">{{ fixedColumnSize - n }}:{{ n - 1 }}</td>
        </tr>
        <tr class="NBold">
          <td :colspan="columnSize">号码分布</td>
          <td :colspan="columnSize">号码跨度</td>
          <td colspan="6">大小比</td>
          <td colspan="6">奇偶比</td>
          <td colspan="6">质合比</td>
        </tr>
      </tfoot>
    </table>
    <div class="charts_exper">
      <h6>参数说明</h6>
      <ul>
        <li><span class="green">[号码分布]</span> 所有开奖号码的开出情况。</li>
        <li><span class="green">[号码跨度]</span>所有开奖号码中最大值和最小值的差值。</li>
        <li><span class="green">[大小比]</span>所有开奖号码的大号和小号个数的比值。大号：5，6，7，8，9；小号：0，1，2，3，4</li>
        <li><span class="green">[奇偶比]</span>所有开奖号码的奇号和偶号个数的比值。奇号：1，3，5，7，9；偶号：0，2，4，6，8</li>
        <li><span class="green">[质合比]</span>所有开奖号码的质号和合号个数的比值。质号：1，2，3，5，7；合号：0，4，6，8，9</li>
        <li><span class="green">[和值]</span> 所有开奖号码之和。</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { common } from '../common.js'
import { commonSort } from '../commonSort.js'
import TrendHandle from "../hs";

const ColumnSize = {
  HSSSC: 10,
  HS115: 11
};

export default {
  mixins: [common, commonSort],
  inject: {
    constant: "constant"
  },
  data() {
    return {
      queryData: [],      
      columnSize: ColumnSize.HSSSC,
      selectCount: 5,
      fixedColumnSize: 6
    };
  },
  mounted() {
    this.queryData = TrendHandle.statistics([], this.constant.TypeDict[this.$route.params.lotto].middle);
    if (this.$store.state.issueNewest) {      
      this.queryData = TrendHandle.statistics(
        this.$store.state.issueNewest.lotteryInfoList,
        this.constant.TypeDict[this.$route.params.lotto].middle
      );
    }
    this.processData();
  },
  methods:{
    processData() {
      if (this.$store.state.issueNewest) {
        this.queryData = TrendHandle.statistics(
          this.$store.state.issueNewest.lotteryInfoList,
          this.constant.TypeDict[this.$route.params.lotto].middle
        );
      }

      if (this.isHS115){
        this.columnSize = ColumnSize.HS115;
        this.queryData.dist = this.queryData.dist.slice(1, 12);        
        this.queryData.diffRadio = this.queryData.diffRadio.slice(0, 11);  
      } else {
        this.columnSize = ColumnSize.HSSSC;
        this.queryData.dist = this.queryData.dist.slice(0, 10);
        this.queryData.diffRadio = this.queryData.diffRadio.slice(0, 10);
      }

      for (let i=0; i < this.queryData.length; i++) {
        if (this.isHS115){
          this.queryData[i].dist = this.queryData[i].dist.slice(1, 12);
          this.queryData[i].diff = this.queryData[i].diff.slice(0, 11);
        } else {
          this.queryData[i].dist = this.queryData[i].dist.slice(0, 10);          
          this.queryData[i].diff = this.queryData[i].diff.slice(0, 10);
        }
      }
    }
  },
  watch: {
    "$store.state.issueNewest": function (newVal) {
      if (newVal) {
        this.processData();     
        this.sortType = "sort_down";
      }
    }
  },
};
</script>