import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from './views/Home';
import LinkerView from './views/Linker';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: {
        path: '/HSPK/History'
      }
    },
    {
      path: '/:lotto/:item',
      name: 'Home',
      component: HomeView
    },
    {
      path: '/linker/:item',
      name: 'Linker',
      component: LinkerView
    }
  ]
});

export default router;
