<template>
<!--时时彩 五星走勢-->
<div class="charts_detail">
  <table class="Dtable Old" width="100%">
    <thead>
      <tr>
        <th width="64" rowspan="2" class="sort"  style="cursor: pointer;" @click="onChangeSort()">
            期号<i :class="`icon ${sortType}`"></i><!--sort_up改sort_down可以替換排序上下箭頭-->
        </th>
        <th width="96" rowspan="2">开奖号码</th>
        <th :colspan="columnSize">万位</th>
        <th :colspan="columnSize">千位</th>
        <th :colspan="columnSize">百位</th>
        <th :colspan="columnSize">十位</th>
        <th :colspan="columnSize">个位</th>
      </tr>
      <tr class="NBold">
        <th v-for="(n, index) in columnSize" :key="'h1' + index">{{ isHS115 ? n : index }}</th>
        <th v-for="(n, index) in columnSize" :key="'h2' + index">{{ isHS115 ? n : index }}</th>
        <th v-for="(n, index) in columnSize" :key="'h3' + index">{{ isHS115 ? n : index }}</th>
        <th v-for="(n, index) in columnSize" :key="'h4' + index">{{ isHS115 ? n : index }}</th>
        <th v-for="(n, index) in columnSize" :key="'h5' + index">{{ isHS115 ? n : index }}</th>
      </tr>
    </thead>
    <tbody id="history_item_box">
      <tr v-for="(item, index) in queryData" :key="index">
          <td>{{ item.issueNo }}</td>
          <td><b>{{ item.numbers.join(",") }}</b></td>
          <template v-for="(subItem, placeIndex) in item.statistic">
            <td v-for="(current, issueIndex) in subItem" :key="`b-${placeIndex}-${issueIndex}`" :class="placeIndex % 2 === 0 ? 'lightblue' : 'lightred'">
              <em :class="`signicon ${current > 0 ? (placeIndex % 2 == 0 ? 'b_1' : 'b_5 ' + placeClass[placeIndex]) : ''}`">
                {{current > 0 ? (isHS115 ? issueIndex + 1 : issueIndex): ''}}
                </em>
            </td>
          </template>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="2">
          当前出现次数<br>（总数:<span class="green" id="totalIssue">{{ queryData.length }}</span>）
        </td>
        <template v-for="(item, placeIndex) in queryData.statisticRadio">
          <td v-for="(current, index) in item" :key="`ff0-${placeIndex}-${index}`" class="lightblue">
            <span :class="`times ${placeClass[placeIndex]} ${index % 2 === 0 ? 
              ((placeIndex % 2 === 0) ? 'bluecolor' : 'redcolor') : 
              ((placeIndex % 2 === 0) ? 'darkBlueColor' : 'pinkcolor')}`">
              <em :style="`height: ${100.0 * current / queryData.statisticRadioMax[placeIndex]}px`"><b><i>{{current}}</i></b></em>
            </span>
          </td>
        </template>
      </tr>
      <tr>
        <td rowspan="2" colspan="2">&nbsp;</td>
        <td v-for="(n, index) in columnSize" :key="'ff1' + index">{{ isHS115 ? n : index }}</td>
        <td v-for="(n, index) in columnSize" :key="'ff2' + index">{{ isHS115 ? n : index }}</td>
        <td v-for="(n, index) in columnSize" :key="'ff3' + index">{{ isHS115 ? n : index }}</td>
        <td v-for="(n, index) in columnSize" :key="'ff4' + index">{{ isHS115 ? n : index }}</td>
        <td v-for="(n, index) in columnSize" :key="'ff5' + index">{{ isHS115 ? n : index }}</td>
      </tr>
      <tr class="NBold">
        <td colspan="10">万位</td>
        <td colspan="10">千位</td>
        <td colspan="10">百位</td>
        <td colspan="10">十位</td>
        <td colspan="10">个位</td>
      </tr>
    </tfoot>
  </table>
  <div class="charts_exper">
    <h6>参数说明</h6>
    <ul>
      <li><span class="green">[号码分布]</span> 所有开奖号码的开出情况。</li>
      <li><span class="green">[万位]</span> 万位上的开奖号码。</li>
      <li><span class="green">[千位]</span> 千位上的开奖号码。</li>
      <li><span class="green">[百位]</span> 百位上的开奖号码。</li>
      <li><span class="green">[十位]</span> 十位上的开奖号码。</li>
      <li><span class="green">[个位]</span> 个位上的开奖号码。</li>
    </ul>
  </div>
</div>
</template>

<script>
import { common } from '../common.js'
import { commonSort } from '../commonSort.js'
import TrendHandle from "../hs";

const ColumnSize = {
  HSSSC: 10,
  HS115: 11
};

export default {
  mixins: [common, commonSort],
  inject: {
    constant: "constant"
  },
  data() {
    return {
      queryData: [],
      columnSize: ColumnSize.HSSSC,
      selectCount: 5,      
      placeClass: ["tenThousand", "thousand", "hundred", "ten", "bits"]
    };
  },
  mounted() {
    this.queryData = TrendHandle.statistics([], this.constant.TypeDict[this.$route.params.lotto].middle);
    if (this.$store.state.issueNewest) {
      this.queryData = TrendHandle.statistics(
        this.$store.state.issueNewest.lotteryInfoList,
        this.constant.TypeDict[this.$route.params.lotto].middle
      ); 
    }
    this.processData();
  },
  methods:{
    processData() {
      if (this.$store.state.issueNewest) {
        this.queryData = TrendHandle.statistics(
          this.$store.state.issueNewest.lotteryInfoList,
          this.constant.TypeDict[this.$route.params.lotto].middle
        );  
      }

      let startIndex = 0;            
      if (this.isHS115){
        this.columnSize = ColumnSize.HS115;        
        startIndex = 1;        
      } else {
        this.columnSize = ColumnSize.HSSSC;        
      }

      let showColumnSize = this.columnSize + startIndex;
      for (let i=0; i<this.queryData.statisticRadio.length; i++){
        this.queryData.statisticRadio[i] = this.queryData.statisticRadio[i].slice(startIndex, showColumnSize)
      }

      for (let i=0; i<this.queryData.length; i++){
        for(let j=0; j<this.queryData[i].statistic.length; j++){
          this.queryData[i].statistic[j] = this.queryData[i].statistic[j].slice(startIndex, showColumnSize)
        }
      }      
    }  
  },
  watch: {
    "$store.state.issueNewest": function (newVal) {
      if (newVal){
        this.processData();     
        this.sortType = "sort_down";
      }
    }
  }
};
</script>