<template>
  <footer>
    <div class="footer_divider"></div>
    <div class="container">
      <div class="footer_content">
        <div class="footer_cooperation">
          <div class="title">合作伙伴</div>
          <div class="links">
            <li><img src="/images/logo_sheffield.png" alt="" /></li>
            <li><img src="/images/logo_pagcor.png" alt="" /></li>
            <li><img src="/images/logo_ncg.png" alt="" /></li>
            <li><img src="/images/logo_saba.png" alt="" /></li>
            <li><img src="/images/logo_ag.png" alt="" /></li>
            <li><img src="/images/logo_iovation.png" alt="" /></li>
            <li><img src="/images/logo_18.png" alt="" /></li>
          </div>
        </div>
        <div class="footer_links">
          <div class="title">快速链结</div>
          <div class="links">
            <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Privacy }}">隐私权政策</router-link></li>
            <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Rule }}">规则与条款</router-link></li>
            <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Problem }}">常见问题</router-link></li>
            <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Rational }}">理性博彩</router-link></li>
          </div>
        </div>
      </div>
      <div class="footer_content footer_info">
        <div class="footer_logo">
          <img src="/images/footer_logo.png" class="logo" alt="" />
          <div class="official_email">
            <img src="/images/ic_mail.svg" alt="" />官方邮箱
            <a href="mailto:heccsd@gmail.com">heccsd@gmail.com</a>
          </div>
        </div>
        <p>© Copyright 2019-2029 {{$productName}} All Rights Reserved</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  inject: {   
    enums: "enums"
  }
};
</script>