<template>
  <div slot="right" class="right_content">
    <ul>
      <li v-for="(item, index) in constant.HistoryQueryItem" :key="index" @click="setQueryRange(index)">
        <a href="javascript:void(0)"
          :class="index === $store.state.historyQueryIdx ? 'select' : ''">{{ item }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { common } from '../common.js'

export default {
  mixins: [common],
  inject: {
    constant: "constant"    
  } 
};
</script>