<template> 
  <div :class="`charts_filter ${ isHSPK ? 'center' : 'space_between' }`">
    <div class="filter_item">
      <i><img :src="isHSPK ? '/images/ic_filter.svg' : '/images/ic_type.svg'" alt="" />{{ isHSPK ? '筛选' : '型态' }}</i>
      <div class="filter_btn">  
        <template v-if="isHSPK">     
          <a 
            href="javascript:void(0)"
            v-for="(item, index) in constant.HistoryQueryItem"
            :key="index"
            @click="setQueryRange(index)"
            :class="index === $store.state.historyQueryIdx ? 'current' : ''"
            >{{ item }}
          </a>
        </template> 
        <template v-else> 
          <a href="javascript:void(0)" @click="setTrendType(0);" :class="isMixType ? 'current' : ''">五星综合</a>
          <a href="javascript:void(0)" @click="setTrendType(1);" :class="!isMixType ? 'current' : ''">五星走势</a>
        </template> 
      </div>
    </div>
    <div class="filter_item" v-if="!isHSPK">
      <i><img src="/images/ic_filter.svg" alt="" />筛选</i>
        <a 
          href="javascript:void(0)"
          v-for="(item, index) in constant.HistoryQueryItem"
          :key="index"
          @click="setQueryRange(index)"
          :class="index === $store.state.historyQueryIdx ? 'current' : ''"
          >{{ item }}
        </a>
    </div>
  </div>   
</template>

<script>
import { common } from '../common.js'

export default {
  mixins: [common],
  inject: {
    constant: "constant"    
  }
};
</script>