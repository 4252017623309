<template>
  <div>
    <office-header></office-header>
    <router-view></router-view>
    <office-footer></office-footer>
  </div>
</template>

<script>
import headerComponent from './components/Header';
import footerComponent from './components/Footer';

export default {
  components: {
    officeHeader: headerComponent,
    officeFooter: footerComponent
  },
}
</script>