<template>
  <div slot="left">
    <div class="m_filter">
      <div class="m_filter_item">
        <select @change="setQueryRange($event.target.value)">
          <option v-for="(item, index) in constant.HistoryQueryItem" :key="index" :value="index">{{ item }}</option>
        </select>
      </div>
    </div>
    <ul class="history_title">
      <li>期号</li>
      <li>开奖时间</li>
      <li>开奖号码</li>
    </ul>
    <ul class="history_list" v-for="(issueInfo, index) in lottoList" :key="index">
      <li>{{ issueInfo.issueNo }}</li>
      <li>{{ issueInfo.issueTime }}</li>
      <li :class="`history_list_number ${ isHSPK ? 'pk10' : 'lotto' }`" >     
        <template v-if="!isHSPK">        
          <div v-for="(drawNumber, nIdx1) in issueInfo.numbers" :key="nIdx1" :class="nIdx1 === 0 || nIdx1 === 4 ? 'ball_orange' : 'ball_cyan'">
            <span>{{ drawNumber }}</span>
          </div>                            
        </template>              
        <div v-else :class="'num_' + $padLeft(drawNumber, '0', 2)" v-for="(drawNumber, nIdx2) in issueInfo.numbers" :key="nIdx2">
          <span>{{ $padLeft(drawNumber, '0', 2) }}</span>
        </div>              
      </li>
    </ul>
    <div class="page_num">
      <li>
        <a href="javascript:void(0)" @click="prevPage()" :class="'arrow ' + (page <= 1 ? 'disable': '')">
          <img src="/images/ic_arrow.svg" alt="">
        </a>
      </li>
      <li v-for="(pageNumber, index) in pages" :key="index">
        <a href="javascript:void(0)" @click="setPages(pageNumber)" :class="(page === pageNumber ? 'select' : '')">
          <span>{{ pageNumber }}</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="nextPage()" :class="'arrow '+ (page >= totalPage ? 'disable': '')">
          <img src="/images/ic_arrow.svg" class="flip" alt="">
        </a>
      </li>
    </div>
    <div style="display:none">{{ hiddenRefresh }}</div>
  </div>
</template>

<script >
import { common } from '../common.js'

const onePageCount = 10;
const LimitCount = {
  c30: 30,
  c100: 100
};
export default {  
  mixins: [common],
  inject: {
    constant: "constant"    
  },
  data() {
    return {
      lottoList: [],
      page: 1,
      totalPage: 0,
      pages: [],
      queryData: null,
      prevRoute: null
    }
  },
  computed:{
    hiddenRefresh: function () {              
      if (this.prevRoute !== this.$route.params.lotto) {      
        this.setPrevRoute();    
        this.initDataChange();        
      }
      return ''; 
    }
  },
  mounted(){    
    if (!this.prevRoute){
      this.prevRoute = this.$route.params.lotto;
    }
    this.initDataChange(this.$store.state.issueNewest);    
  },
  methods: {
    setPages(pageNum) {
            
      if (!this.queryData){
          return;
      }
          
      this.pages = [];
      this.totalPage = Math.ceil((this.queryData.lotteryInfoList?.length / onePageCount));

      if (this.$store.state.historyQueryIdx === 1){
        this.totalPage = Math.floor(LimitCount.c30 / onePageCount);
      } else if (this.$store.state.historyQueryIdx === 2){
        this.totalPage = Math.floor(LimitCount.c100 / onePageCount);
      }
      
      let minPage = pageNum - 2 > 0 ? pageNum - 2 : 1;      
      let maxPage = minPage + 4;
      if(maxPage >= this.totalPage){
        maxPage = this.totalPage;
        minPage = maxPage - 4 > 0 ? maxPage - 4 : 1;
      }
      
      this.page = pageNum > maxPage ? maxPage : (pageNum < minPage ? minPage : pageNum); 

      this.lottoList = this.queryData.lotteryInfoList?.slice((this.page - 1) * onePageCount, this.page * onePageCount);    
         
      for(let i = minPage; i <= maxPage; i++){
        this.pages.push(i);
      }
    },
    prevPage(){      
      if (this.page - 1 < 1){
        return;
      }
      this.page--;
      this.setPages(this.page);
    },
    nextPage(){                  
      if (this.page + 1 > this.totalPage){
        return;
      }
      this.page++;
      this.setPages(this.page);
    },
    setPrevRoute(){
      this.prevRoute = this.$route.params.lotto;
    },
    initDataChange(val){
      let te = (val) ? val.lotto : null;
      if (te !== null && this.$route.params.lotto === te){                
        this.queryData = val;
        this.setPages(this.page);    
      } else {        
        this.lottoList = [];
        this.page = 1;
        this.totalPage = 0;
        this.pages = [];
      }      
    }
  },
  watch: {
    "$store.state.issueNewest": function (newVal){      
      this.initDataChange(newVal);
    }
  }
};
</script>