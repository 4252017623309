const TypeDict = {
    "HS115":{
        name:"lotto_11pick5",
        desc:" 11选5",
        interval:1,
        content: { 
            "Code": "200", 
            "Message": "", 
            "Success": true, 
            "Result": [ { 
                "IssueNo": 1906211144, 
                "IssueTime": "2019-06-21 11:44:00", 
                "LottorNums": [4, 1, 7, 6, 3] } ] 
            },
        dateFilter: [1440, 30, 100, 2880, 7200],
        middle: 6
    },
    "HSPK":{
        name:"pk10",
        desc:" PK拾",
        interval:3,
        content: { 
            "Code": "200", 
            "Message": "", 
            "Success": true, 
            "Result": [ { 
                "IssueNo": 1906211144, 
                "IssueTime": "2019-06-21 11:44:00", 
                "LottorNums": [4, 1, 7, 6, 3, 2, 10, 8, 9, 5] } ] 
            },
        dateFilter: [1440, 90, 300, 2880, 7200],
        middle: 5
    },
    "HSSFC":{
        name:"lotto_3min",
        desc:" 3分彩",
        interval:3,
        content: { 
            "Code": "200", 
            "Message": "", 
            "Success": true, 
            "Result": [ { 
                "IssueNo": 1906211144, 
                "IssueTime": "2019-06-21 11:44:00", 
                "LottorNums": [4, 1, 7, 6, 3] } ] 
            },
        dateFilter: [1440, 90, 300, 2880, 7200],
        middle: 5
    },
    "HSSSC":{
        name:"lotto_hour",
        desc:"时时彩",
        interval:1,
        content: { 
            "Code": "200", 
            "Message": "", 
            "Success": true, 
            "Result": [ { 
                "IssueNo": 1906211144, 
                "IssueTime": "2019-06-21 11:44:00", 
                "LottorNums": [4, 1, 7, 6, 3] } ] 
            },
        dateFilter: [1440, 30, 100, 2880, 7200],
        middle: 5
    }
}

const HistoryQueryItem = [
    '今日数据',
    '近30期',
    '近100期',
    '近2天',
    '近5天'
  ];

const QueryByAllDate = [0, 3, 4];

export default {
    TypeDict,
    HistoryQueryItem,
    QueryByAllDate
};