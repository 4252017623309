import enums from './enums';

export const common = {
    methods: {
        setQueryRange(idx) {                        
            this.$store.commit('updateHistoryQueryIdx', idx);
        },
        setTrendType(idx) {            
            this.$store.commit("updateTrendTypeIdx", idx);
        }
    },
    computed: {
        isHSPK: function () {
            return this.$route.params.lotto === enums.LOTTO_TYPE.HSPK;
        },
        isHSSSC: function () {
            return this.$route.params.lotto === enums.LOTTO_TYPE.HSSSC;
        },
        isHSSFC: function () {
            return this.$route.params.lotto === enums.LOTTO_TYPE.HSSFC;
        },
        isHS115: function () {
            return this.$route.params.lotto === enums.LOTTO_TYPE.HS115;
        },
        isHistory: function () {
            return this.$route.params.item === enums.LOTTO_ACTION_TYPE.History;
        },
        isTrend: function () {
            return this.$route.params.item === enums.LOTTO_ACTION_TYPE.Trend;
        },
        isRule: function () {
            return this.$route.params.item === enums.LOTTO_ACTION_TYPE.Rule;
        },
        isApi: function () {
            return this.$route.params.item === enums.LOTTO_ACTION_TYPE.Api;
        },
        isPrivacy: function () {
            return this.$route.params.item === this.enums.LINKER_TYPE.Privacy;
        },
        isRule: function () {
            return this.$route.params.item === this.enums.LINKER_TYPE.Rule;
        },
        isProblem: function () {
            return this.$route.params.item === this.enums.LINKER_TYPE.Problem;
        },
        isRational: function () {
            return this.$route.params.item === this.enums.LINKER_TYPE.Rational;
        },
        isMixType: function () {
            return this.$store.state.trendTypeIdx === 0;
        }
    }
};