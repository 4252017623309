<template>
  <div>
    <div class="container">
      <div class="terms_tabs">
        <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Privacy }}" :class="(isPrivacy ? 'select':'')">隐私权政策</router-link></li>
        <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Rule }}" :class="(isRule ? 'select':'')">规则与条款</router-link></li>
        <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Problem }}" :class="(isProblem ? 'select':'')">常见问题</router-link></li>
        <li><router-link :to="{ name: 'Linker', params: { item: enums.LINKER_TYPE.Rational }}" :class="(isRational ? 'select':'')">理性博彩</router-link></li>
      </div>
    </div>
    <div class="container terms_content">
      <div class="terms_article">
        <privacy v-if="isPrivacy" />
        <rule v-if="isRule" />
        <problem v-if="isProblem" />
        <rational v-if="isRational" />        
      </div>
      <div class="terms_pattern">
        <img src="/images/logo_text.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { common } from '../common.js'
import privacy from "../components/linker/Privacy";
import problem from "../components/linker/Problem";
import rational from "../components/linker/Rational";
import rule from "../components/linker/Rule";

export default {
  mixins: [common],
  inject: {   
    enums: "enums"
  },
  components: {
    privacy,
    problem,
    rational,
    rule,
  }
};
</script>