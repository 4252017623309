<template>
  <header>
    <div class="fast_link">
      <div class="container">
        <div class="links">
          <li>
            <a href="https://www.haisiwoaim.com/" target="_blank">
              <img src="/images/ic_user.svg" width="13" eight="15" alt=""/>
              <span>官网登录</span>
            </a>
          </li>
          <li @click="addFavorite()">
            <a href="javascript:void(0)">
              <img src="/images/ic_favorite.svg" width="16" height="15" alt=""/>
              <span>收藏网址</span>
            </a>
          </li>
        </div>
      </div>
    </div>
    <nav>
      <div class="container nav_gap">
        <div class="nav_link">
          <div class="logo">
            <router-link :to="`/HSPK/History`"></router-link>
          </div>
          <ul>
            <li class="page_btn">
              <ol><router-link :to="'/HSPK/History'" :class="isHSPK ? 'select': ''">{{ hspkName }}</router-link></ol>
              <ol><router-link :to="'/HSSSC/History'" :class="isHSSSC ? 'select': ''">{{ hssscName }}</router-link></ol>
              <ol><router-link :to="'/HSSFC/History'" :class="isHSSFC ? 'select': ''">{{ hssfcName }}</router-link></ol>
              <ol><router-link :to="'/HS115/History'" :class="isHS115 ? 'select': ''">{{ hs115Name }}</router-link></ol>
            </li>
          </ul>
          <ul class="m_burger" @click="onTaggleMenu()">
            <a href="javascript:void(0)"><img src="/images/ic_burger.svg" alt=""/></a>
          </ul>
        </div>
      </div>
    </nav>
    <transition name="slide-fade">
    <div class="m_nav" v-show="isMenuOpen">
      <ul class="close" @click="onCloseMenu()">
        <a href="javascript:void(0)"><img src="/images/ic_close.svg" alt=""/></a>
      </ul>
      <ul class="page_btn">
        <li><router-link :to="'/HSPK/History'" :class="isHSPK ? 'select': ''">{{ hspkName }}</router-link></li>
        <li><router-link :to="'/HSSSC/History'" :class="isHSSSC ? 'select': ''">{{ hssscName }}</router-link></li>
        <li><router-link :to="'/HSSFC/History'" :class="isHSSFC ? 'select': ''">{{ hssfcName }}</router-link></li>
        <li><router-link :to="'/HS115/History'" :class="isHS115 ? 'select': ''">{{ hs115Name }}</router-link></li>
      </ul>
      <ul class="fast_link">
        <li @click="gotoWebSite()">
          <a id="officialLogin" href="http://www.zgshwyh.com/" target="_blank">
            <img src="/images/ic_user.svg" alt="" />官网登录
          </a>
        </li>
        <li @click="addFavorite()">
          <a href="javascript:void(0)"><img src="/images/ic_favorite.svg" alt="" />收藏网址</a>
        </li>
      </ul>
    </div>
    </transition>
  </header>
</template>

<script>
import { common } from '../common.js'

export default {
  mixins: [common],
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    hspkName: function (){
      return this.$productName + ' PK拾';
    },
    hssscName: function (){
      return this.$productName + '时时彩';
    },
    hssfcName: function (){
      return this.$productName + ' 3分彩';
    },
    hs115Name: function (){
      return this.$productName + ' 11选5';
    }
  },
  methods: {
    addFavorite() {
        if (window.sidebar && window.sidebar.addPanel) { // Mozilla Firefox Bookmark
            window.sidebar.addPanel(document.title, window.location.href, "");
        } else if (window.external && ("AddFavorite" in window.external)) { // IE Favorite
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) { // Opera Hotlist
            this.title = document.title;
            return true;
        } else { // webkit - safari/chrome
            alert(`Press ${ (navigator.userAgent.toLowerCase().indexOf("mac") != -1 ? 'Command/Cmd' : 'CTRL') } + D to bookmark this page.`);
        }
        return false;
    },
    gotoWebSite() {
      this.onTaggleMenu();
      document.getElementById("officialLogin").click;
    },
    onCloseMenu() {
      this.isMenuOpen = false;
    },
    onTaggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
  watch: {
    "$route.params.lotto": function (newVal) {      
      this.onTaggleMenu();
    }
  }
};
</script>